/*** COLOR CODES 3 **/
/**
 * arches - Global style system focused on utility classes
 * @version v4.1.4x
 * @link https://github.com/ACC-Style/Arches/
 */
/**  Build Comment: including file /setup/__preheader.scss  **/
/** SCSS DOC: _preheader.scss **/
/** SCSS DOC: _helpers.scss **/
/** SCSS DOC: _utility-css.vars.scss **/
/**  Build Comment: This Style Sheet should only be used as an augmenting stylesheet  **/
/**  Build Comment: Set Active Class  **/
/**  No Brands Are Used for small project that need to be included with a larger base style sheet.    *********************************/
/**  Colors  **/
/** SCSS DOC: __brand.none.scss **/
/** SCSS DOC: _colors.scss **/
/** Setting link color variables in scss **/
/*

Link Colors: Light

Link colors of a lighter shade in all of its states, default, hover, visited. Typically used to enhance visibility on dark backgrounds.

$link-color-dark			- #126657; Link Color dark			
$link-color-dark--hover	- #051f1a; Link Color dark--hover	
$link-color-dark--visited	- #42665f; Link Color dark--visited	

Weight:2

Styleguide DesignBase.Colors.LinksLight
 */
/*

Link Colors

Link colors  in all of its states, default, hover, visited.


$link-color-light			- #8cebda; Link Color light			
$link-color-light--hover	- #e8fbf8; Link Color light--hover	
$link-color-light--visited	- #84afa7; Link Color light--visited	
$link-color-dark			- #126657; Link Color dark			
$link-color-dark--hover	- #051f1a; Link Color dark--hover	
$link-color-dark--visited	- #42665f; Link Color dark--visited	

Weight:1

Styleguide DesignBase.Colors.Links
 */
/**  Build Comment: Start with No Branding 4.1.4x This is for an augmenting color sheets that don't require a brand  **/
/** SCSS DOC: __var.output.scss **/
/** This needs to run later then brand **/
/** SCSS DOC: _setup.none.scss **/
/**  Build Comment: credits Color Stylesheet  **/
.bg_AAPA {
  background-color: #e8c536 !important;
}

.bg_AAPA-0 {
  background-color: #e8c536 !important;
}

.bg_AAPA-1 {
  background-color: #eacb4a !important;
}

.bg_AAPA-2 {
  background-color: #eed468 !important;
}

.bg_AAPA-3 {
  background-color: #f4e29b !important;
}

.bg_AAPA-4 {
  background-color: #f9f1cd !important;
}

.bg_AAPA-5 {
  background-color: #fdf9eb !important;
}

.bg_AAPA-n1 {
  background-color: #dab419 !important;
}

.bg_AAPA-n2 {
  background-color: #b49415 !important;
}

.bg_AAPA-n3 {
  background-color: #806a0f !important;
}

.bg_AAPA-n4 {
  background-color: #4d4009 !important;
}

.bg_AAPA-n5 {
  background-color: #262004 !important;
}

.c_AAPA {
  color: #e8c536 !important;
}

.c_AAPA-0 {
  color: #e8c536 !important;
}

.c_AAPA-1 {
  color: #eacb4a !important;
}

.c_AAPA-2 {
  color: #eed468 !important;
}

.c_AAPA-3 {
  color: #f4e29b !important;
}

.c_AAPA-4 {
  color: #f9f1cd !important;
}

.c_AAPA-5 {
  color: #fdf9eb !important;
}

.c_AAPA-n1 {
  color: #dab419 !important;
}

.c_AAPA-n2 {
  color: #b49415 !important;
}

.c_AAPA-n3 {
  color: #806a0f !important;
}

.c_AAPA-n4 {
  color: #4d4009 !important;
}

.c_AAPA-n5 {
  color: #262004 !important;
}

.br_AAPA {
  border-color: #e8c536 !important;
}

.br_AAPA-0 {
  border-color: #e8c536 !important;
}

.br_AAPA-1 {
  border-color: #eacb4a !important;
}

.br_AAPA-2 {
  border-color: #eed468 !important;
}

.br_AAPA-3 {
  border-color: #f4e29b !important;
}

.br_AAPA-4 {
  border-color: #f9f1cd !important;
}

.br_AAPA-5 {
  border-color: #fdf9eb !important;
}

.br_AAPA-n1 {
  border-color: #dab419 !important;
}

.br_AAPA-n2 {
  border-color: #b49415 !important;
}

.br_AAPA-n3 {
  border-color: #806a0f !important;
}

.br_AAPA-n4 {
  border-color: #4d4009 !important;
}

.br_AAPA-n5 {
  border-color: #262004 !important;
}

.bg_ABP {
  background-color: #007a5b !important;
}

.bg_ABP-0 {
  background-color: #007a5b !important;
}

.bg_ABP-1 {
  background-color: #00a178 !important;
}

.bg_ABP-2 {
  background-color: #00dba3 !important;
}

.bg_ABP-3 {
  background-color: #3dffce !important;
}

.bg_ABP-4 {
  background-color: #9effe6 !important;
}

.bg_ABP-5 {
  background-color: #d8fff5 !important;
}

.bg_ABP-n1 {
  background-color: #00684d !important;
}

.bg_ABP-n2 {
  background-color: #005540 !important;
}

.bg_ABP-n3 {
  background-color: #003d2e !important;
}

.bg_ABP-n4 {
  background-color: #00251b !important;
}

.bg_ABP-n5 {
  background-color: #00120e !important;
}

.c_ABP {
  color: #007a5b !important;
}

.c_ABP-0 {
  color: #007a5b !important;
}

.c_ABP-1 {
  color: #00a178 !important;
}

.c_ABP-2 {
  color: #00dba3 !important;
}

.c_ABP-3 {
  color: #3dffce !important;
}

.c_ABP-4 {
  color: #9effe6 !important;
}

.c_ABP-5 {
  color: #d8fff5 !important;
}

.c_ABP-n1 {
  color: #00684d !important;
}

.c_ABP-n2 {
  color: #005540 !important;
}

.c_ABP-n3 {
  color: #003d2e !important;
}

.c_ABP-n4 {
  color: #00251b !important;
}

.c_ABP-n5 {
  color: #00120e !important;
}

.br_ABP {
  border-color: #007a5b !important;
}

.br_ABP-0 {
  border-color: #007a5b !important;
}

.br_ABP-1 {
  border-color: #00a178 !important;
}

.br_ABP-2 {
  border-color: #00dba3 !important;
}

.br_ABP-3 {
  border-color: #3dffce !important;
}

.br_ABP-4 {
  border-color: #9effe6 !important;
}

.br_ABP-5 {
  border-color: #d8fff5 !important;
}

.br_ABP-n1 {
  border-color: #00684d !important;
}

.br_ABP-n2 {
  border-color: #005540 !important;
}

.br_ABP-n3 {
  border-color: #003d2e !important;
}

.br_ABP-n4 {
  border-color: #00251b !important;
}

.br_ABP-n5 {
  border-color: #00120e !important;
}

.bg_ABPMOCII {
  background-color: #0b6d4c !important;
}

.bg_ABPMOCII-0 {
  background-color: #0b6d4c !important;
}

.bg_ABPMOCII-1 {
  background-color: #0f9065 !important;
}

.bg_ABPMOCII-2 {
  background-color: #14c68a !important;
}

.bg_ABPMOCII-3 {
  background-color: #4eedb8 !important;
}

.bg_ABPMOCII-4 {
  background-color: #a6f6db !important;
}

.bg_ABPMOCII-5 {
  background-color: #dcfbf1 !important;
}

.bg_ABPMOCII-n1 {
  background-color: #095d41 !important;
}

.bg_ABPMOCII-n2 {
  background-color: #084c35 !important;
}

.bg_ABPMOCII-n3 {
  background-color: #063726 !important;
}

.bg_ABPMOCII-n4 {
  background-color: #032117 !important;
}

.bg_ABPMOCII-n5 {
  background-color: #02100b !important;
}

.c_ABPMOCII {
  color: #0b6d4c !important;
}

.c_ABPMOCII-0 {
  color: #0b6d4c !important;
}

.c_ABPMOCII-1 {
  color: #0f9065 !important;
}

.c_ABPMOCII-2 {
  color: #14c68a !important;
}

.c_ABPMOCII-3 {
  color: #4eedb8 !important;
}

.c_ABPMOCII-4 {
  color: #a6f6db !important;
}

.c_ABPMOCII-5 {
  color: #dcfbf1 !important;
}

.c_ABPMOCII-n1 {
  color: #095d41 !important;
}

.c_ABPMOCII-n2 {
  color: #084c35 !important;
}

.c_ABPMOCII-n3 {
  color: #063726 !important;
}

.c_ABPMOCII-n4 {
  color: #032117 !important;
}

.c_ABPMOCII-n5 {
  color: #02100b !important;
}

.br_ABPMOCII {
  border-color: #0b6d4c !important;
}

.br_ABPMOCII-0 {
  border-color: #0b6d4c !important;
}

.br_ABPMOCII-1 {
  border-color: #0f9065 !important;
}

.br_ABPMOCII-2 {
  border-color: #14c68a !important;
}

.br_ABPMOCII-3 {
  border-color: #4eedb8 !important;
}

.br_ABPMOCII-4 {
  border-color: #a6f6db !important;
}

.br_ABPMOCII-5 {
  border-color: #dcfbf1 !important;
}

.br_ABPMOCII-n1 {
  border-color: #095d41 !important;
}

.br_ABPMOCII-n2 {
  border-color: #084c35 !important;
}

.br_ABPMOCII-n3 {
  border-color: #063726 !important;
}

.br_ABPMOCII-n4 {
  border-color: #032117 !important;
}

.br_ABPMOCII-n5 {
  border-color: #02100b !important;
}

.bg_ABPMOCIV {
  background-color: #105a3b !important;
}

.bg_ABPMOCIV-0 {
  background-color: #105a3b !important;
}

.bg_ABPMOCIV-1 {
  background-color: #167c51 !important;
}

.bg_ABPMOCIV-2 {
  background-color: #1fb073 !important;
}

.bg_ABPMOCIV-3 {
  background-color: #53e1a5 !important;
}

.bg_ABPMOCIV-4 {
  background-color: #a9f0d2 !important;
}

.bg_ABPMOCIV-5 {
  background-color: #ddf9ed !important;
}

.bg_ABPMOCIV-n1 {
  background-color: #0e4d32 !important;
}

.bg_ABPMOCIV-n2 {
  background-color: #0b3f29 !important;
}

.bg_ABPMOCIV-n3 {
  background-color: #082d1e !important;
}

.bg_ABPMOCIV-n4 {
  background-color: #051b12 !important;
}

.bg_ABPMOCIV-n5 {
  background-color: #020e09 !important;
}

.c_ABPMOCIV {
  color: #105a3b !important;
}

.c_ABPMOCIV-0 {
  color: #105a3b !important;
}

.c_ABPMOCIV-1 {
  color: #167c51 !important;
}

.c_ABPMOCIV-2 {
  color: #1fb073 !important;
}

.c_ABPMOCIV-3 {
  color: #53e1a5 !important;
}

.c_ABPMOCIV-4 {
  color: #a9f0d2 !important;
}

.c_ABPMOCIV-5 {
  color: #ddf9ed !important;
}

.c_ABPMOCIV-n1 {
  color: #0e4d32 !important;
}

.c_ABPMOCIV-n2 {
  color: #0b3f29 !important;
}

.c_ABPMOCIV-n3 {
  color: #082d1e !important;
}

.c_ABPMOCIV-n4 {
  color: #051b12 !important;
}

.c_ABPMOCIV-n5 {
  color: #020e09 !important;
}

.br_ABPMOCIV {
  border-color: #105a3b !important;
}

.br_ABPMOCIV-0 {
  border-color: #105a3b !important;
}

.br_ABPMOCIV-1 {
  border-color: #167c51 !important;
}

.br_ABPMOCIV-2 {
  border-color: #1fb073 !important;
}

.br_ABPMOCIV-3 {
  border-color: #53e1a5 !important;
}

.br_ABPMOCIV-4 {
  border-color: #a9f0d2 !important;
}

.br_ABPMOCIV-5 {
  border-color: #ddf9ed !important;
}

.br_ABPMOCIV-n1 {
  border-color: #0e4d32 !important;
}

.br_ABPMOCIV-n2 {
  border-color: #0b3f29 !important;
}

.br_ABPMOCIV-n3 {
  border-color: #082d1e !important;
}

.br_ABPMOCIV-n4 {
  border-color: #051b12 !important;
}

.br_ABPMOCIV-n5 {
  border-color: #020e09 !important;
}

.bg_ATCEU {
  background-color: #775744 !important;
}

.bg_ATCEU-0 {
  background-color: #775744 !important;
}

.bg_ATCEU-1 {
  background-color: #8c6650 !important;
}

.bg_ATCEU-2 {
  background-color: #a77d65 !important;
}

.bg_ATCEU-3 {
  background-color: #c4a998 !important;
}

.bg_ATCEU-4 {
  background-color: #e2d4cc !important;
}

.bg_ATCEU-5 {
  background-color: #f3eeea !important;
}

.bg_ATCEU-n1 {
  background-color: #654a3a !important;
}

.bg_ATCEU-n2 {
  background-color: #533d30 !important;
}

.bg_ATCEU-n3 {
  background-color: #3c2c22 !important;
}

.bg_ATCEU-n4 {
  background-color: #241a14 !important;
}

.bg_ATCEU-n5 {
  background-color: #120d0a !important;
}

.c_ATCEU {
  color: #775744 !important;
}

.c_ATCEU-0 {
  color: #775744 !important;
}

.c_ATCEU-1 {
  color: #8c6650 !important;
}

.c_ATCEU-2 {
  color: #a77d65 !important;
}

.c_ATCEU-3 {
  color: #c4a998 !important;
}

.c_ATCEU-4 {
  color: #e2d4cc !important;
}

.c_ATCEU-5 {
  color: #f3eeea !important;
}

.c_ATCEU-n1 {
  color: #654a3a !important;
}

.c_ATCEU-n2 {
  color: #533d30 !important;
}

.c_ATCEU-n3 {
  color: #3c2c22 !important;
}

.c_ATCEU-n4 {
  color: #241a14 !important;
}

.c_ATCEU-n5 {
  color: #120d0a !important;
}

.br_ATCEU {
  border-color: #775744 !important;
}

.br_ATCEU-0 {
  border-color: #775744 !important;
}

.br_ATCEU-1 {
  border-color: #8c6650 !important;
}

.br_ATCEU-2 {
  border-color: #a77d65 !important;
}

.br_ATCEU-3 {
  border-color: #c4a998 !important;
}

.br_ATCEU-4 {
  border-color: #e2d4cc !important;
}

.br_ATCEU-5 {
  border-color: #f3eeea !important;
}

.br_ATCEU-n1 {
  border-color: #654a3a !important;
}

.br_ATCEU-n2 {
  border-color: #533d30 !important;
}

.br_ATCEU-n3 {
  border-color: #3c2c22 !important;
}

.br_ATCEU-n4 {
  border-color: #241a14 !important;
}

.br_ATCEU-n5 {
  border-color: #120d0a !important;
}

.bg_CE {
  background-color: #a8a8a8 !important;
}

.bg_CE-0 {
  background-color: #a8a8a8 !important;
}

.bg_CE-1 {
  background-color: #b1b1b1 !important;
}

.bg_CE-2 {
  background-color: #bebebe !important;
}

.bg_CE-3 {
  background-color: #d4d4d4 !important;
}

.bg_CE-4 {
  background-color: #e9e9e9 !important;
}

.bg_CE-5 {
  background-color: #f6f6f6 !important;
}

.bg_CE-n1 {
  background-color: #8f8f8f !important;
}

.bg_CE-n2 {
  background-color: #767676 !important;
}

.bg_CE-n3 {
  background-color: #545454 !important;
}

.bg_CE-n4 {
  background-color: #323232 !important;
}

.bg_CE-n5 {
  background-color: #191919 !important;
}

.c_CE {
  color: #a8a8a8 !important;
}

.c_CE-0 {
  color: #a8a8a8 !important;
}

.c_CE-1 {
  color: #b1b1b1 !important;
}

.c_CE-2 {
  color: #bebebe !important;
}

.c_CE-3 {
  color: #d4d4d4 !important;
}

.c_CE-4 {
  color: #e9e9e9 !important;
}

.c_CE-5 {
  color: #f6f6f6 !important;
}

.c_CE-n1 {
  color: #8f8f8f !important;
}

.c_CE-n2 {
  color: #767676 !important;
}

.c_CE-n3 {
  color: #545454 !important;
}

.c_CE-n4 {
  color: #323232 !important;
}

.c_CE-n5 {
  color: #191919 !important;
}

.br_CE {
  border-color: #a8a8a8 !important;
}

.br_CE-0 {
  border-color: #a8a8a8 !important;
}

.br_CE-1 {
  border-color: #b1b1b1 !important;
}

.br_CE-2 {
  border-color: #bebebe !important;
}

.br_CE-3 {
  border-color: #d4d4d4 !important;
}

.br_CE-4 {
  border-color: #e9e9e9 !important;
}

.br_CE-5 {
  border-color: #f6f6f6 !important;
}

.br_CE-n1 {
  border-color: #8f8f8f !important;
}

.br_CE-n2 {
  border-color: #767676 !important;
}

.br_CE-n3 {
  border-color: #545454 !important;
}

.br_CE-n4 {
  border-color: #323232 !important;
}

.br_CE-n5 {
  border-color: #191919 !important;
}

.bg_CME {
  background-color: #00a15b !important;
}

.bg_CME-0 {
  background-color: #00a15b !important;
}

.bg_CME-1 {
  background-color: #00c46f !important;
}

.bg_CME-2 {
  background-color: #00f88c !important;
}

.bg_CME-3 {
  background-color: #51ffb3 !important;
}

.bg_CME-4 {
  background-color: #a8ffd9 !important;
}

.bg_CME-5 {
  background-color: #dcfff0 !important;
}

.bg_CME-n1 {
  background-color: #00894d !important;
}

.bg_CME-n2 {
  background-color: #007140 !important;
}

.bg_CME-n3 {
  background-color: #00512e !important;
}

.bg_CME-n4 {
  background-color: #00301b !important;
}

.bg_CME-n5 {
  background-color: #00180e !important;
}

.c_CME {
  color: #00a15b !important;
}

.c_CME-0 {
  color: #00a15b !important;
}

.c_CME-1 {
  color: #00c46f !important;
}

.c_CME-2 {
  color: #00f88c !important;
}

.c_CME-3 {
  color: #51ffb3 !important;
}

.c_CME-4 {
  color: #a8ffd9 !important;
}

.c_CME-5 {
  color: #dcfff0 !important;
}

.c_CME-n1 {
  color: #00894d !important;
}

.c_CME-n2 {
  color: #007140 !important;
}

.c_CME-n3 {
  color: #00512e !important;
}

.c_CME-n4 {
  color: #00301b !important;
}

.c_CME-n5 {
  color: #00180e !important;
}

.br_CME {
  border-color: #00a15b !important;
}

.br_CME-0 {
  border-color: #00a15b !important;
}

.br_CME-1 {
  border-color: #00c46f !important;
}

.br_CME-2 {
  border-color: #00f88c !important;
}

.br_CME-3 {
  border-color: #51ffb3 !important;
}

.br_CME-4 {
  border-color: #a8ffd9 !important;
}

.br_CME-5 {
  border-color: #dcfff0 !important;
}

.br_CME-n1 {
  border-color: #00894d !important;
}

.br_CME-n2 {
  border-color: #007140 !important;
}

.br_CME-n3 {
  border-color: #00512e !important;
}

.br_CME-n4 {
  border-color: #00301b !important;
}

.br_CME-n5 {
  border-color: #00180e !important;
}

.bg_CNE {
  background-color: #198dae !important;
}

.bg_CNE-0 {
  background-color: #198dae !important;
}

.bg_CNE-1 {
  background-color: #1da3c9 !important;
}

.bg_CNE-2 {
  background-color: #33bbe2 !important;
}

.bg_CNE-3 {
  background-color: #77d2eb !important;
}

.bg_CNE-4 {
  background-color: #bbe8f5 !important;
}

.bg_CNE-5 {
  background-color: #e4f6fb !important;
}

.bg_CNE-n1 {
  background-color: #157894 !important;
}

.bg_CNE-n2 {
  background-color: #12637a !important;
}

.bg_CNE-n3 {
  background-color: #0d4757 !important;
}

.bg_CNE-n4 {
  background-color: #082a34 !important;
}

.bg_CNE-n5 {
  background-color: #04151a !important;
}

.c_CNE {
  color: #198dae !important;
}

.c_CNE-0 {
  color: #198dae !important;
}

.c_CNE-1 {
  color: #1da3c9 !important;
}

.c_CNE-2 {
  color: #33bbe2 !important;
}

.c_CNE-3 {
  color: #77d2eb !important;
}

.c_CNE-4 {
  color: #bbe8f5 !important;
}

.c_CNE-5 {
  color: #e4f6fb !important;
}

.c_CNE-n1 {
  color: #157894 !important;
}

.c_CNE-n2 {
  color: #12637a !important;
}

.c_CNE-n3 {
  color: #0d4757 !important;
}

.c_CNE-n4 {
  color: #082a34 !important;
}

.c_CNE-n5 {
  color: #04151a !important;
}

.br_CNE {
  border-color: #198dae !important;
}

.br_CNE-0 {
  border-color: #198dae !important;
}

.br_CNE-1 {
  border-color: #1da3c9 !important;
}

.br_CNE-2 {
  border-color: #33bbe2 !important;
}

.br_CNE-3 {
  border-color: #77d2eb !important;
}

.br_CNE-4 {
  border-color: #bbe8f5 !important;
}

.br_CNE-5 {
  border-color: #e4f6fb !important;
}

.br_CNE-n1 {
  border-color: #157894 !important;
}

.br_CNE-n2 {
  border-color: #12637a !important;
}

.br_CNE-n3 {
  border-color: #0d4757 !important;
}

.br_CNE-n4 {
  border-color: #082a34 !important;
}

.br_CNE-n5 {
  border-color: #04151a !important;
}

.bg_CNErx {
  background-color: #52549e !important;
}

.bg_CNErx-0 {
  background-color: #52549e !important;
}

.bg_CNErx-1 {
  background-color: #5f61ac !important;
}

.bg_CNErx-2 {
  background-color: #7a7bba !important;
}

.bg_CNErx-3 {
  background-color: #a6a7d1 !important;
}

.bg_CNErx-4 {
  background-color: #d3d3e8 !important;
}

.bg_CNErx-5 {
  background-color: #ededf6 !important;
}

.bg_CNErx-n1 {
  background-color: #464786 !important;
}

.bg_CNErx-n2 {
  background-color: #393b6f !important;
}

.bg_CNErx-n3 {
  background-color: #292a4f !important;
}

.bg_CNErx-n4 {
  background-color: #19192f !important;
}

.bg_CNErx-n5 {
  background-color: #0c0d18 !important;
}

.c_CNErx {
  color: #52549e !important;
}

.c_CNErx-0 {
  color: #52549e !important;
}

.c_CNErx-1 {
  color: #5f61ac !important;
}

.c_CNErx-2 {
  color: #7a7bba !important;
}

.c_CNErx-3 {
  color: #a6a7d1 !important;
}

.c_CNErx-4 {
  color: #d3d3e8 !important;
}

.c_CNErx-5 {
  color: #ededf6 !important;
}

.c_CNErx-n1 {
  color: #464786 !important;
}

.c_CNErx-n2 {
  color: #393b6f !important;
}

.c_CNErx-n3 {
  color: #292a4f !important;
}

.c_CNErx-n4 {
  color: #19192f !important;
}

.c_CNErx-n5 {
  color: #0c0d18 !important;
}

.br_CNErx {
  border-color: #52549e !important;
}

.br_CNErx-0 {
  border-color: #52549e !important;
}

.br_CNErx-1 {
  border-color: #5f61ac !important;
}

.br_CNErx-2 {
  border-color: #7a7bba !important;
}

.br_CNErx-3 {
  border-color: #a6a7d1 !important;
}

.br_CNErx-4 {
  border-color: #d3d3e8 !important;
}

.br_CNErx-5 {
  border-color: #ededf6 !important;
}

.br_CNErx-n1 {
  border-color: #464786 !important;
}

.br_CNErx-n2 {
  border-color: #393b6f !important;
}

.br_CNErx-n3 {
  border-color: #292a4f !important;
}

.br_CNErx-n4 {
  border-color: #19192f !important;
}

.br_CNErx-n5 {
  border-color: #0c0d18 !important;
}

.bg_COP {
  background-color: #775891 !important;
}

.bg_COP-0 {
  background-color: #775891 !important;
}

.bg_COP-1 {
  background-color: #8564a1 !important;
}

.bg_COP-2 {
  background-color: #997eb1 !important;
}

.bg_COP-3 {
  background-color: #bba9cb !important;
}

.bg_COP-4 {
  background-color: #ddd4e5 !important;
}

.bg_COP-5 {
  background-color: #f1eef5 !important;
}

.bg_COP-n1 {
  background-color: #654b7b !important;
}

.bg_COP-n2 {
  background-color: #533e66 !important;
}

.bg_COP-n3 {
  background-color: #3c2c49 !important;
}

.bg_COP-n4 {
  background-color: #241a2c !important;
}

.bg_COP-n5 {
  background-color: #120d16 !important;
}

.c_COP {
  color: #775891 !important;
}

.c_COP-0 {
  color: #775891 !important;
}

.c_COP-1 {
  color: #8564a1 !important;
}

.c_COP-2 {
  color: #997eb1 !important;
}

.c_COP-3 {
  color: #bba9cb !important;
}

.c_COP-4 {
  color: #ddd4e5 !important;
}

.c_COP-5 {
  color: #f1eef5 !important;
}

.c_COP-n1 {
  color: #654b7b !important;
}

.c_COP-n2 {
  color: #533e66 !important;
}

.c_COP-n3 {
  color: #3c2c49 !important;
}

.c_COP-n4 {
  color: #241a2c !important;
}

.c_COP-n5 {
  color: #120d16 !important;
}

.br_COP {
  border-color: #775891 !important;
}

.br_COP-0 {
  border-color: #775891 !important;
}

.br_COP-1 {
  border-color: #8564a1 !important;
}

.br_COP-2 {
  border-color: #997eb1 !important;
}

.br_COP-3 {
  border-color: #bba9cb !important;
}

.br_COP-4 {
  border-color: #ddd4e5 !important;
}

.br_COP-5 {
  border-color: #f1eef5 !important;
}

.br_COP-n1 {
  border-color: #654b7b !important;
}

.br_COP-n2 {
  border-color: #533e66 !important;
}

.br_COP-n3 {
  border-color: #3c2c49 !important;
}

.br_COP-n4 {
  border-color: #241a2c !important;
}

.br_COP-n5 {
  border-color: #120d16 !important;
}

.bg_CPE {
  background-color: #992e2e !important;
}

.bg_CPE-0 {
  background-color: #992e2e !important;
}

.bg_CPE-1 {
  background-color: #b13535 !important;
}

.bg_CPE-2 {
  background-color: #c94c4c !important;
}

.bg_CPE-3 {
  background-color: #db8787 !important;
}

.bg_CPE-4 {
  background-color: #edc3c3 !important;
}

.bg_CPE-5 {
  background-color: #f8e7e7 !important;
}

.bg_CPE-n1 {
  background-color: #822727 !important;
}

.bg_CPE-n2 {
  background-color: #6b2020 !important;
}

.bg_CPE-n3 {
  background-color: #4d1717 !important;
}

.bg_CPE-n4 {
  background-color: #2e0e0e !important;
}

.bg_CPE-n5 {
  background-color: #170707 !important;
}

.c_CPE {
  color: #992e2e !important;
}

.c_CPE-0 {
  color: #992e2e !important;
}

.c_CPE-1 {
  color: #b13535 !important;
}

.c_CPE-2 {
  color: #c94c4c !important;
}

.c_CPE-3 {
  color: #db8787 !important;
}

.c_CPE-4 {
  color: #edc3c3 !important;
}

.c_CPE-5 {
  color: #f8e7e7 !important;
}

.c_CPE-n1 {
  color: #822727 !important;
}

.c_CPE-n2 {
  color: #6b2020 !important;
}

.c_CPE-n3 {
  color: #4d1717 !important;
}

.c_CPE-n4 {
  color: #2e0e0e !important;
}

.c_CPE-n5 {
  color: #170707 !important;
}

.br_CPE {
  border-color: #992e2e !important;
}

.br_CPE-0 {
  border-color: #992e2e !important;
}

.br_CPE-1 {
  border-color: #b13535 !important;
}

.br_CPE-2 {
  border-color: #c94c4c !important;
}

.br_CPE-3 {
  border-color: #db8787 !important;
}

.br_CPE-4 {
  border-color: #edc3c3 !important;
}

.br_CPE-5 {
  border-color: #f8e7e7 !important;
}

.br_CPE-n1 {
  border-color: #822727 !important;
}

.br_CPE-n2 {
  border-color: #6b2020 !important;
}

.br_CPE-n3 {
  border-color: #4d1717 !important;
}

.br_CPE-n4 {
  border-color: #2e0e0e !important;
}

.br_CPE-n5 {
  border-color: #170707 !important;
}

.bg_ECME {
  background-color: #d16528 !important;
}

.bg_ECME-0 {
  background-color: #d16528 !important;
}

.bg_ECME-1 {
  background-color: #d9733a !important;
}

.bg_ECME-2 {
  background-color: #e08b5b !important;
}

.bg_ECME-3 {
  background-color: #eab191 !important;
}

.bg_ECME-4 {
  background-color: #f5d8c8 !important;
}

.bg_ECME-5 {
  background-color: #fbefe9 !important;
}

.bg_ECME-n1 {
  background-color: #b25622 !important;
}

.bg_ECME-n2 {
  background-color: #92471c !important;
}

.bg_ECME-n3 {
  background-color: #693314 !important;
}

.bg_ECME-n4 {
  background-color: #3f1e0c !important;
}

.bg_ECME-n5 {
  background-color: #1f0f06 !important;
}

.c_ECME {
  color: #d16528 !important;
}

.c_ECME-0 {
  color: #d16528 !important;
}

.c_ECME-1 {
  color: #d9733a !important;
}

.c_ECME-2 {
  color: #e08b5b !important;
}

.c_ECME-3 {
  color: #eab191 !important;
}

.c_ECME-4 {
  color: #f5d8c8 !important;
}

.c_ECME-5 {
  color: #fbefe9 !important;
}

.c_ECME-n1 {
  color: #b25622 !important;
}

.c_ECME-n2 {
  color: #92471c !important;
}

.c_ECME-n3 {
  color: #693314 !important;
}

.c_ECME-n4 {
  color: #3f1e0c !important;
}

.c_ECME-n5 {
  color: #1f0f06 !important;
}

.br_ECME {
  border-color: #d16528 !important;
}

.br_ECME-0 {
  border-color: #d16528 !important;
}

.br_ECME-1 {
  border-color: #d9733a !important;
}

.br_ECME-2 {
  border-color: #e08b5b !important;
}

.br_ECME-3 {
  border-color: #eab191 !important;
}

.br_ECME-4 {
  border-color: #f5d8c8 !important;
}

.br_ECME-5 {
  border-color: #fbefe9 !important;
}

.br_ECME-n1 {
  border-color: #b25622 !important;
}

.br_ECME-n2 {
  border-color: #92471c !important;
}

.br_ECME-n3 {
  border-color: #693314 !important;
}

.br_ECME-n4 {
  border-color: #3f1e0c !important;
}

.br_ECME-n5 {
  border-color: #1f0f06 !important;
}

.bg_IPCE {
  background-color: #6c36b3 !important;
}

.bg_IPCE-0 {
  background-color: #6c36b3 !important;
}

.bg_IPCE-1 {
  background-color: #793fc5 !important;
}

.bg_IPCE-2 {
  background-color: #905fcf !important;
}

.bg_IPCE-3 {
  background-color: #b595df !important;
}

.bg_IPCE-4 {
  background-color: #dacaef !important;
}

.bg_IPCE-5 {
  background-color: #f0eaf9 !important;
}

.bg_IPCE-n1 {
  background-color: #5c2e98 !important;
}

.bg_IPCE-n2 {
  background-color: #4c267d !important;
}

.bg_IPCE-n3 {
  background-color: #361b5a !important;
}

.bg_IPCE-n4 {
  background-color: #201036 !important;
}

.bg_IPCE-n5 {
  background-color: #10081b !important;
}

.c_IPCE {
  color: #6c36b3 !important;
}

.c_IPCE-0 {
  color: #6c36b3 !important;
}

.c_IPCE-1 {
  color: #793fc5 !important;
}

.c_IPCE-2 {
  color: #905fcf !important;
}

.c_IPCE-3 {
  color: #b595df !important;
}

.c_IPCE-4 {
  color: #dacaef !important;
}

.c_IPCE-5 {
  color: #f0eaf9 !important;
}

.c_IPCE-n1 {
  color: #5c2e98 !important;
}

.c_IPCE-n2 {
  color: #4c267d !important;
}

.c_IPCE-n3 {
  color: #361b5a !important;
}

.c_IPCE-n4 {
  color: #201036 !important;
}

.c_IPCE-n5 {
  color: #10081b !important;
}

.br_IPCE {
  border-color: #6c36b3 !important;
}

.br_IPCE-0 {
  border-color: #6c36b3 !important;
}

.br_IPCE-1 {
  border-color: #793fc5 !important;
}

.br_IPCE-2 {
  border-color: #905fcf !important;
}

.br_IPCE-3 {
  border-color: #b595df !important;
}

.br_IPCE-4 {
  border-color: #dacaef !important;
}

.br_IPCE-5 {
  border-color: #f0eaf9 !important;
}

.br_IPCE-n1 {
  border-color: #5c2e98 !important;
}

.br_IPCE-n2 {
  border-color: #4c267d !important;
}

.br_IPCE-n3 {
  border-color: #361b5a !important;
}

.br_IPCE-n4 {
  border-color: #201036 !important;
}

.br_IPCE-n5 {
  border-color: #10081b !important;
}

.bg_MOC {
  background-color: #9a1267 !important;
}

.bg_MOC-0 {
  background-color: #9a1267 !important;
}

.bg_MOC-1 {
  background-color: #b8167b !important;
}

.bg_MOC-2 {
  background-color: #e41c99 !important;
}

.bg_MOC-3 {
  background-color: #ed68bb !important;
}

.bg_MOC-4 {
  background-color: #f6b3dd !important;
}

.bg_MOC-5 {
  background-color: #fbe1f1 !important;
}

.bg_MOC-n1 {
  background-color: #830f58 !important;
}

.bg_MOC-n2 {
  background-color: #6c0d48 !important;
}

.bg_MOC-n3 {
  background-color: #4d0934 !important;
}

.bg_MOC-n4 {
  background-color: #2e051f !important;
}

.bg_MOC-n5 {
  background-color: #17030f !important;
}

.c_MOC {
  color: #9a1267 !important;
}

.c_MOC-0 {
  color: #9a1267 !important;
}

.c_MOC-1 {
  color: #b8167b !important;
}

.c_MOC-2 {
  color: #e41c99 !important;
}

.c_MOC-3 {
  color: #ed68bb !important;
}

.c_MOC-4 {
  color: #f6b3dd !important;
}

.c_MOC-5 {
  color: #fbe1f1 !important;
}

.c_MOC-n1 {
  color: #830f58 !important;
}

.c_MOC-n2 {
  color: #6c0d48 !important;
}

.c_MOC-n3 {
  color: #4d0934 !important;
}

.c_MOC-n4 {
  color: #2e051f !important;
}

.c_MOC-n5 {
  color: #17030f !important;
}

.br_MOC {
  border-color: #9a1267 !important;
}

.br_MOC-0 {
  border-color: #9a1267 !important;
}

.br_MOC-1 {
  border-color: #b8167b !important;
}

.br_MOC-2 {
  border-color: #e41c99 !important;
}

.br_MOC-3 {
  border-color: #ed68bb !important;
}

.br_MOC-4 {
  border-color: #f6b3dd !important;
}

.br_MOC-5 {
  border-color: #fbe1f1 !important;
}

.br_MOC-n1 {
  border-color: #830f58 !important;
}

.br_MOC-n2 {
  border-color: #6c0d48 !important;
}

.br_MOC-n3 {
  border-color: #4d0934 !important;
}

.br_MOC-n4 {
  border-color: #2e051f !important;
}

.br_MOC-n5 {
  border-color: #17030f !important;
}

.bg_MOCII {
  background-color: #9a1267 !important;
}

.bg_MOCII-0 {
  background-color: #9a1267 !important;
}

.bg_MOCII-1 {
  background-color: #b8167b !important;
}

.bg_MOCII-2 {
  background-color: #e41c99 !important;
}

.bg_MOCII-3 {
  background-color: #ed68bb !important;
}

.bg_MOCII-4 {
  background-color: #f6b3dd !important;
}

.bg_MOCII-5 {
  background-color: #fbe1f1 !important;
}

.bg_MOCII-n1 {
  background-color: #830f58 !important;
}

.bg_MOCII-n2 {
  background-color: #6c0d48 !important;
}

.bg_MOCII-n3 {
  background-color: #4d0934 !important;
}

.bg_MOCII-n4 {
  background-color: #2e051f !important;
}

.bg_MOCII-n5 {
  background-color: #17030f !important;
}

.c_MOCII {
  color: #9a1267 !important;
}

.c_MOCII-0 {
  color: #9a1267 !important;
}

.c_MOCII-1 {
  color: #b8167b !important;
}

.c_MOCII-2 {
  color: #e41c99 !important;
}

.c_MOCII-3 {
  color: #ed68bb !important;
}

.c_MOCII-4 {
  color: #f6b3dd !important;
}

.c_MOCII-5 {
  color: #fbe1f1 !important;
}

.c_MOCII-n1 {
  color: #830f58 !important;
}

.c_MOCII-n2 {
  color: #6c0d48 !important;
}

.c_MOCII-n3 {
  color: #4d0934 !important;
}

.c_MOCII-n4 {
  color: #2e051f !important;
}

.c_MOCII-n5 {
  color: #17030f !important;
}

.br_MOCII {
  border-color: #9a1267 !important;
}

.br_MOCII-0 {
  border-color: #9a1267 !important;
}

.br_MOCII-1 {
  border-color: #b8167b !important;
}

.br_MOCII-2 {
  border-color: #e41c99 !important;
}

.br_MOCII-3 {
  border-color: #ed68bb !important;
}

.br_MOCII-4 {
  border-color: #f6b3dd !important;
}

.br_MOCII-5 {
  border-color: #fbe1f1 !important;
}

.br_MOCII-n1 {
  border-color: #830f58 !important;
}

.br_MOCII-n2 {
  border-color: #6c0d48 !important;
}

.br_MOCII-n3 {
  border-color: #4d0934 !important;
}

.br_MOCII-n4 {
  border-color: #2e051f !important;
}

.br_MOCII-n5 {
  border-color: #17030f !important;
}

.bg_MOCIV {
  background-color: #d3248d !important;
}

.bg_MOCIV-0 {
  background-color: #d3248d !important;
}

.bg_MOCIV-1 {
  background-color: #dd3599 !important;
}

.bg_MOCIV-2 {
  background-color: #e256aa !important;
}

.bg_MOCIV-3 {
  background-color: #ec8fc7 !important;
}

.bg_MOCIV-4 {
  background-color: #f5c7e3 !important;
}

.bg_MOCIV-5 {
  background-color: #fbe9f4 !important;
}

.bg_MOCIV-n1 {
  background-color: #b31f78 !important;
}

.bg_MOCIV-n2 {
  background-color: #941963 !important;
}

.bg_MOCIV-n3 {
  background-color: #6a1247 !important;
}

.bg_MOCIV-n4 {
  background-color: #3f0b2a !important;
}

.bg_MOCIV-n5 {
  background-color: #200515 !important;
}

.c_MOCIV {
  color: #d3248d !important;
}

.c_MOCIV-0 {
  color: #d3248d !important;
}

.c_MOCIV-1 {
  color: #dd3599 !important;
}

.c_MOCIV-2 {
  color: #e256aa !important;
}

.c_MOCIV-3 {
  color: #ec8fc7 !important;
}

.c_MOCIV-4 {
  color: #f5c7e3 !important;
}

.c_MOCIV-5 {
  color: #fbe9f4 !important;
}

.c_MOCIV-n1 {
  color: #b31f78 !important;
}

.c_MOCIV-n2 {
  color: #941963 !important;
}

.c_MOCIV-n3 {
  color: #6a1247 !important;
}

.c_MOCIV-n4 {
  color: #3f0b2a !important;
}

.c_MOCIV-n5 {
  color: #200515 !important;
}

.br_MOCIV {
  border-color: #d3248d !important;
}

.br_MOCIV-0 {
  border-color: #d3248d !important;
}

.br_MOCIV-1 {
  border-color: #dd3599 !important;
}

.br_MOCIV-2 {
  border-color: #e256aa !important;
}

.br_MOCIV-3 {
  border-color: #ec8fc7 !important;
}

.br_MOCIV-4 {
  border-color: #f5c7e3 !important;
}

.br_MOCIV-5 {
  border-color: #fbe9f4 !important;
}

.br_MOCIV-n1 {
  border-color: #b31f78 !important;
}

.br_MOCIV-n2 {
  border-color: #941963 !important;
}

.br_MOCIV-n3 {
  border-color: #6a1247 !important;
}

.br_MOCIV-n4 {
  border-color: #3f0b2a !important;
}

.br_MOCIV-n5 {
  border-color: #200515 !important;
}

.bg_ACHE {
  background-color: #9733a5 !important;
}

.bg_ACHE-0 {
  background-color: #9733a5 !important;
}

.bg_ACHE-1 {
  background-color: #ac3abb !important;
}

.bg_ACHE-2 {
  background-color: #bd57cb !important;
}

.bg_ACHE-3 {
  background-color: #d38fdc !important;
}

.bg_ACHE-4 {
  background-color: #e9c7ee !important;
}

.bg_ACHE-5 {
  background-color: #f6e9f8 !important;
}

.bg_ACHE-n1 {
  background-color: #802b8c !important;
}

.bg_ACHE-n2 {
  background-color: #6a2474 !important;
}

.bg_ACHE-n3 {
  background-color: #4c1a53 !important;
}

.bg_ACHE-n4 {
  background-color: #2d0f32 !important;
}

.bg_ACHE-n5 {
  background-color: #170819 !important;
}

.c_ACHE {
  color: #9733a5 !important;
}

.c_ACHE-0 {
  color: #9733a5 !important;
}

.c_ACHE-1 {
  color: #ac3abb !important;
}

.c_ACHE-2 {
  color: #bd57cb !important;
}

.c_ACHE-3 {
  color: #d38fdc !important;
}

.c_ACHE-4 {
  color: #e9c7ee !important;
}

.c_ACHE-5 {
  color: #f6e9f8 !important;
}

.c_ACHE-n1 {
  color: #802b8c !important;
}

.c_ACHE-n2 {
  color: #6a2474 !important;
}

.c_ACHE-n3 {
  color: #4c1a53 !important;
}

.c_ACHE-n4 {
  color: #2d0f32 !important;
}

.c_ACHE-n5 {
  color: #170819 !important;
}

.br_ACHE {
  border-color: #9733a5 !important;
}

.br_ACHE-0 {
  border-color: #9733a5 !important;
}

.br_ACHE-1 {
  border-color: #ac3abb !important;
}

.br_ACHE-2 {
  border-color: #bd57cb !important;
}

.br_ACHE-3 {
  border-color: #d38fdc !important;
}

.br_ACHE-4 {
  border-color: #e9c7ee !important;
}

.br_ACHE-5 {
  border-color: #f6e9f8 !important;
}

.br_ACHE-n1 {
  border-color: #802b8c !important;
}

.br_ACHE-n2 {
  border-color: #6a2474 !important;
}

.br_ACHE-n3 {
  border-color: #4c1a53 !important;
}

.br_ACHE-n4 {
  border-color: #2d0f32 !important;
}

.br_ACHE-n5 {
  border-color: #170819 !important;
}

/** SCSS DOC: __globalshame_uc.scss **/